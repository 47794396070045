



















































import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  name: 'CommonConfirmDialog',
  data () {
    return {
      icon: null as string | null,
      title: null as string | null,
      warning: null as string | null,
      importantInformation: null as string | null,
      confirmationText: null as string | null,
      cancelText: null as string | null,
      confirmButtonColor: null as null | string,
      cancelButtonColor: null as string | null,
      hideImportantInformation: false,
      confirmAction: null as Function | null
    }
  },
  computed: {
    ...mapGetters({
      confirmDialog: 'dialog/getConfirmDialog',
      confirmDialogLoader: 'dialog/getConfirmDialogLoader'
    })
  },
  created () {
    this.setData()
  },
  methods: {
    ...mapActions({
      closeConfirmDialog: 'dialog/closeConfirmDialog'
    }),
    setData () {
      this.icon = this.confirmDialog.data.icon || '$alert'
      this.title = this.confirmDialog.data.title || this.$trans('confirmation_modal_header')
      this.warning = this.confirmDialog.data.warning || this.$trans('confirmation_modal_warning')
      this.importantInformation = this.confirmDialog.data.importantInformation || this.$trans('no_way_to_undo_action')
      this.confirmationText = this.confirmDialog.data.confirmationText || this.$trans('ok')
      this.cancelText = this.confirmDialog.data.cancelText || this.$trans('cancel')
      this.cancelButtonColor = this.confirmDialog.data.cancelButtonColor || 'accent'
      this.confirmAction = this.confirmDialog.confirmHandler || this.closeConfirmDialog
      this.confirmButtonColor = this.confirmDialog.data.confirmButtonColor || 'error'
    }
  }
})
